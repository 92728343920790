@import '../../style/vars';

.product-specifications-wrapper.fita-tiras .product-specifications{

  .box {
    width: 100%;
    background: #fff;
    margin-top: -45px;
    padding: 35px 25px 0;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.15);

    @include desktop {
      margin-top: 78px;
    }

  }

  .container {
    @include desktop {
      padding: 0;
    }
  }

  h3 {
    font-size: 35px;
    color: $primaryColor;
    text-align: center;
    letter-spacing: 2px;
    padding-bottom: 40px;

    @include desktop {
      font-size: 50px;
      padding-bottom: 50px;
    }

  }

  .specification-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include desktop {
      flex-wrap: nowrap;
      padding-bottom: 60px;
    }

  }

  .specification-list-item {
    width: 100%;
    text-align: center;
    padding: 5px 0 40px 0;

    @include desktop {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede;

      &:last-child {
        border-right: none;
      }

    }

    &.left {
      @include desktop {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .icon-wrapper {
          width: 100px;
        }

        .text {
          width: 328px;
          font-size: 20px;
          line-height: 25px;
        }
      }
    }

    .title {
      font-size: 42px;
      color: #636363 !important;
      font-weight: bold;

      @include desktop {
        font-size: 29px !important;
        line-height: 29px !important;
        margin-bottom: 10px;
        margin-top: 20px;
        color: #636363 !important;
        font-weight: normal !important;
      }
    }

    ul {
      list-style: disc inside;
      padding-top: 15px;
      li {
        font-weight: bold;
        font-size: 21px;
        line-height: 30px;

        @include desktop {
          font-size: 16px;
          line-height: 26px;
        }

      }

    }

    .icon-wrapper {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: center;
    }

    .icon {
      padding-bottom: 30px;
      align-self: center;
    }

    .text {
      font-size: 23px;
      line-height: 29px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: justify;
      font-weight: 300;
      color: $textColor;

      @include desktop {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal;
      }

      p {

        padding: 15px 0;

        @include desktop {
          padding: 5px 0;
        }

        span {
          font-weight: bold;
        }

        &:last-child {
          padding-bottom: 40px;
          border-bottom: 1px solid #dedede;

          @include desktop {
            border-bottom: none;
          }
        }

      }

    }

    &:last-child p:last-child {
      border-bottom: none;
    }

  }

}