$desktop-width: 1075px;
$primaryColor: #e35060;
$textColor: #676767;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin placeholder($color) {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $color;
  }
}